import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import App from './App';
import awsconfig from './aws-exports';
import { AmplifyStateProvider } from './utils/hooks/use-amplify-state';

Amplify.configure(awsconfig);

render(
    <BrowserRouter>
        <AmplifyStateProvider>
            <App />
        </AmplifyStateProvider>
    </BrowserRouter>,
    document.getElementById('root')
);
