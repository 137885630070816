import React, {lazy, Suspense} from 'react';
import {BrowserRouter as Router, RouteProps, Switch} from 'react-router-dom';
import NavRoute, {NavComponent} from './components/nav-route';
import {NavigationItem} from './components/navigation';

const NavigationComponent = React.lazy(() => import('./components/navigation'));

interface SystemNavItemRouteProps extends NavigationItem, RouteProps {}
const navigationItems: SystemNavItemRouteProps[] = [
    {
        exact: true,
        props: {
            href: '/',
        },
        text: 'Home',
    },
    {
        exact: true,
        props: {
            href: '/about',
        },
        text: 'About',
    },
    {
        exact: false,
        guard: [],
        path: ['/systems/:system', '/systems'],
        props: {
            href: '/systems',
        },
        text: 'Systems',
    },
    {
        exact: false,
        guard: 'admin',
        path: ['/administration/:tab', '/administration'],
        props: {
            href: '/administration',
        },
        text: 'Administration',
    },
];

const navComponents: {[index: string]: NavComponent} = {
    About: lazy(() => import('./routes/about')),
    Administration: lazy(() => import('./routes/administration')),
    Home: lazy(() => import('./routes/home')),
    Login: lazy(() => import('./routes/login')),
    Systems: lazy(() => import('./routes/systems')),
};

const App = (): JSX.Element => {
    return (
        <Router>
            <Suspense fallback={null}>
                <NavigationComponent navigationItems={navigationItems}/>
            </Suspense>
            <Suspense fallback={null}>
                <Switch>
                    <NavRoute exact path='/login' component={navComponents.Login} />
                    {navigationItems.map(({props, text, ...rest}) => (
                        <NavRoute key={text} {...rest} path={rest?.path || props.href} component={navComponents[text]}/>
                    ))}
                </Switch>
            </Suspense>
        </Router>
    );
};

export {App as default};
