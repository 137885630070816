import React from 'react';
import { Route, RouteProps, RouteComponentProps } from 'react-router-dom';

export type NavComponent =
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>
    | React.ReactElement<any>;

export interface NavRouteProps extends Omit<RouteProps, 'component'> {
    component: NavComponent;
    componentProps?: any;
}

const NavRoute = ({
    component: Component,
    componentProps,
    ...rest
}: NavRouteProps): JSX.Element => (
    <Route
        {...rest}
        render={(props): JSX.Element => (
            <div id="content">
                {React.isValidElement(Component) ? (
                    React.cloneElement(Component, {
                        ...componentProps,
                        ...props,
                    })
                ) : (
                    <Component {...componentProps} {...props} />
                )}
            </div>
        )}
    />
);

export { NavRoute as default };
