import { CognitoUser } from "@aws-amplify/auth";
import { useReducer } from "react";
import { createContainer } from 'react-tracked';

export interface AmplifyState {
    amplifyLoading: boolean;
    previousUser?: CognitoUser | 'guest';
}

const _useAmplifyState = () =>
    useReducer(
        (state: AmplifyState, newValue: Partial<AmplifyState>) => ({
            ...state,
            ...newValue,
        }),
        {
            amplifyLoading: true,
        }
    );

export const {
    Provider: AmplifyStateProvider,
    useTracked: useAmplifyState,
} = createContainer(_useAmplifyState);