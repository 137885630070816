/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:7083ea0f-8744-4a91-8067-3fbbe3bbab8b",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_M33fdvKo4",
    "aws_user_pools_web_client_id": "5ikdtius9t3ta76v62d21opbdc",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://qpvsei57b5eu7fj3iouvdrbh6u.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://6mme2lxczf.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        },
        {
            "name": "powerBi",
            "endpoint": "https://6wnm6x30nd.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        },
        {
            "name": "storageAPI",
            "endpoint": "https://khbikwr5p2.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        },
        {
            "name": "awsQuickSightsAPI",
            "endpoint": "https://9a5wz44gg7.execute-api.us-west-2.amazonaws.com/production",
            "region": "us-west-2"
        }
    ],
    "aws_user_files_s3_bucket": "site-documents104506-production",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
